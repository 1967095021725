<!-- 排3基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1629609108732" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="2399" width="45" height="45">
                    <path d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z" fill="#322C2B"
                        p-id="2400"></path>
                    <path d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
                        fill="#0C7CBE" p-id="2401"></path>
                    <path d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
                        fill="#EFB918" p-id="2402"></path>
                    <path d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
                        fill="#14934A" p-id="2403"></path>
                    <path d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z"
                        fill="#D7282A" p-id="2404"></path>
                </svg>
                    <div class="ssq-one">
                        <h2>中国体育彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>排 列 3 基 本 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a b">期号</th>
                        <th rowspan="2" class="ranks_a1">开奖</th>
                        <th colspan="10" class="ranks_b">百位</th>
                        <th colspan="10" class="ranks_b">十位</th>
                        <th colspan="10" class="ranks_b">个位</th>
                        <th colspan="28" class="ranks_b">和值</th>
                    </tr>
                   
                    <tr>
                        <th class="ranks_c" v-for="(item , i) in 10" :key="i">{{item-1}}</th>
                        <th class="ranks_c" v-for="(item , i) in 10" :key="i">{{item-1}}</th>
                        <th class="ranks_c" v-for="(item , i) in 10" :key="i">{{item-1}}</th>
                        <th class="ranks_c" v-for="(item , i) in 28" :key="i">{{item-1}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in pl35" :key='x'>
                        <td>{{item2.expect}}</td>
                        <td>
                            <div>
                                {{item2.red[0] }} {{item2.red[1] }} {{item2.red[2] }}
                            </div>
                        </td>
                        <td  class="b-red" v-for="(item ,i) in q1" :key='i' >
                            <div  :class="item == item2.red[0] ? 'q-red': '' "  >
                                {{item == item2.red[0] ? item: '' }}
                            </div>
                        </td>
                        <td  class="b-lan" v-for="(item ,i) in q1" :key='i'>
                            <div :class="item == item2.red[1] ? 'q-lan': '' " >
                                {{item == item2.red[1] ? item: '' }}
                            </div>
                        </td>
                        <td  class="b-red" v-for="(item ,i) in q1" :key='i'>
                            <div :class="item == item2.red[2] ? 'q-red': '' " >
                                {{item == item2.red[2] ? item: '' }}
                            </div>
                        </td>
                        <td  class="b-lan" v-for="(item ,i) in q2" :key='i'>
                            <div :class="item == (parseInt(item2.red[0])+parseInt(item2.red[1])+parseInt(item2.red[2])) ? 'q-he': ''" >
                                {{item == (parseInt(item2.red[0])+parseInt(item2.red[1])+parseInt(item2.red[2])) ? item: '' }}
                            </div>
                        </td>
                    </tr>
                    
                    <tr>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
     import { mapState, mapMutations, mapActions } from 'vuex'
    import axios from 'axios'
    import config from '@/utils/config.js'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'red-basic',
        components: {

        },
        data() { // 数据源
            return {
                q1:[0,1, 2, 3, 4, 5, 6, 7, 8, 9],
                q2:[0,1, 2, 3, 4, 5, 6, 7, 8, 9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27],
             
            }
        },
        mounted() {
            this.mssq50()
            this.toTop()
        },
        computed: {
            ...mapState(['pl35'])
        },
        methods: { // 函数体部分，js的主要逻辑控制   
            ...mapMutations(['mutationpl35']),
            ...mapActions(['actionpl35']),
            toTop() {
                document.documentElement.scrollTop = 0;
            },
            mssq50() {//走势图
                if (this.pl35 == "") {
                    axios({
                        method: "post",
                        url: config.urlcom + "lottery/demo/findj",
                        data: {
                            'tab': "pl5",
                            'zd': "issueno",
                            'i': 50,
                        },
                    }).then(  //this.url是我在上面定义的一个固定的url
                        (res) => {
                            var data = []
                            var x = res.data.reverse()
                            x.forEach((item) => {
                                var a =item.number.split(' ')
                                var red=[]
                                var red5=[]
                                red.push(a[0],a[1],a[2])
                                red5.push(a[0],a[1],a[2],a[3],a[4])
                                var obj = {
                                    "expect": item.issueno,
                                    "red": red,
                                    "red5":red5,
                                }
                                data.push(obj)
                            })
                            this.actionpl35(data)
                        },
                    )
                    //alert('重新请求数据basic页')
                } else {
                   // alert('vux返回数据basic页')
                    return this.$store.state.pl35;
                }
            },            
        },
        props: {
        },
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: rgb(224, 202, 5);
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }
    .ranks_a1{
        width:40px;
    }
    .ranks_c {
        width:23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }
    .b-red{
        background-color: rgb(191, 224, 191);
    }
    .b-lan{
        background-color: #f8fcb2;
    }

    
    .q-red {
        background-color: rgb(87, 28, 114);
        border-radius: 15px;
        color: #fff;
    }
    .q-lan {
        background-color: #548ce0;
        border-radius: 15px;
        color: #fff;
    }
    .q-he{
        background-color: #22940b;
        border-radius: 15px;
        color: #fff;
    }
    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    
</style>